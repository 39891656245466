<template>
  <div class="stepper-comp-wrapper">
    <button
      class="left"
      aria-label="Decrease quantity"
      :disabled="minDisabled"
      :aria-disabled="minDisabled"
      @click="
        () => {
          state.counter > min ? state.counter-- : {};
          valChange();
        }
      "
    >
      <SubtractLine />
    </button>
    <!-- eslint-disable-next-line vue-a11y/label-has-for -->
    <label :for="`${state.id}-quantity-input`" style="display: none"> Input Quantity </label>
    <input
      :id="`${state.id}-quantity-input`"
      v-model="state.counter"
      type="number"
      :min="min"
      :max="max"
      class="num-input"
      aria-label="Input Quantity"
      @keyup.enter="$event.target?.blur()"
      @change="valChange"
    />
    <button
      class="right"
      aria-label="Increase quantity"
      :disabled="maxDisabled"
      :aria-disabled="maxDisabled"
      @click="
        () => {
          state.counter++;
          valChange();
        }
      "
    >
      <AddLine />
    </button>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['stepper-update']);

const props = defineProps({
  counterStart: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number || null,
    default: null,
    required: false,
  },
  min: {
    type: Number,
    default: 1,
    required: false,
  },
});

const state = reactive({
  counter: props.counterStart,
  id: Math.random() * 100000,
});

const minDisabled = computed<boolean>(() => {
  return state.counter <= props.min;
});
const maxDisabled = computed<boolean>(() => {
  return props.max ? state.counter >= props.max : false;
});

function valChange() {
  emit('stepper-update', state.counter);
}
</script>

<style lang="scss" scoped>
.stepper-comp-wrapper {
  display: flex;
}

input,
button {
  height: 28px;
  box-sizing: border-box;
  text-align: center;
  color: $color-neutral-cool-700;
  font-size: 19px;
  font-weight: 500;
}

input {
  width: 28px;
  margin: 0 6px;
  border: none;

  &:focus {
    color: $color-secondary-500;
  }
}

button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  border-radius: 50%;
  background-color: $color-neutral-white;
  border: 1px $color-neutral-cool-700 solid;
  cursor: pointer;

  // &.left {
  //   border-radius: 4px 0 0 4px;
  // }

  // &.right {
  //   border-radius: 0 4px 4px 0;
  // }

  &:focus {
    border-color: $color-secondary-500;

    // :deep(svg) {
    //   fill: $color-secondary-500;
    // }
  }

  &:hover:not(:disabled) {
    border-color: $color-neutral-cool-500;
    background-color: $color-neutral-cool-500;

    :deep(svg) {
      fill: $color-neutral-white;
    }
  }

  &:disabled {
    cursor: not-allowed;
    border-color: $color-neutral-cool-300;

    :deep(svg) {
      fill: $color-neutral-cool-300;
    }
  }
}

// Hide Input arrows
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
